const socialMedia = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/facundo-franchino-6438821aa/",
  },
  {
    name: "Github",
    url: "https://github.com/cucuwritescode",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/facundo.franchino/",
  },
  {
    name: "Spotify (old project)",
    url: "https://open.spotify.com/artist/6XNEnhRJe6VJDbDrOgZ8mk",
  },
];

export default socialMedia;
